import { SVGProps } from "react";
import { cloneElement, isValidElement, ReactNode } from "react";

interface NodeIconProps extends SVGProps<SVGElement> {
  children: ReactNode;
}

export default function NodeIcon({ children, ...otherProps }: NodeIconProps) {
  if (typeof children === "string") return <>{children}</>;

  return (
    <>
      {children &&
        isValidElement(children) &&
        cloneElement(children, otherProps)}
    </>
  );
}
