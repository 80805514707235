import { Arrow } from "../icons";
import { RoundedCta } from "../ctas";
import { TextCta } from "../ctas";
import { useEffect, useRef, useState } from "react";
import {
  ITopicCarouselFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import EmblaCarousel from "./EmblaCarousel";
import { CenteredImageZoomInOnView } from "../shared";
import { motion } from "framer-motion";
import useIntersectionObserver from "../utils/useIntersectionObserver";
import FlutterText from "../animations/flutterText";

export type TopicCarouselProps = {
  fields: SpecificLocaleFields<ITopicCarouselFields>;
};

export default function TopicCarousel({ fields }: TopicCarouselProps) {
  const [_selected, setSelected] = useState(0);
  const _innerRef = useRef<HTMLDivElement>(null);

  const entry = useIntersectionObserver(_innerRef, {});
  const isVisible = !!entry?.isIntersecting;
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setReset(true);
        setTimeout(() => {
          setReset(false);
        }, 500);
      }, 2000);
    }
  }, [isVisible]);

  useEffect(() => {
    if (fields && fields.topics) {
      fields.topics.sort(function (a, b) {
        if (a.fields.title < b.fields.title) {
          return -1;
        }
        if (a.fields.title > b.fields.title) {
          return 1;
        }
        return 0;
      });
    }
  });

  const navigationCallback = function (index: number) {
    setSelected(index);
  };

  return (
    <section
      className="tbi-grid w-full py-10 lg:py-16"
      data-content-type="topicCarousel"
      data-module-title={fields.moduleTitle}
      ref={_innerRef}
    >
      <div className="col-span-12 flex items-center pb-6">
        <h4 className={"grow lgmax:mr-auto lgmax:max-w-[250px] lgmax:pl-4"}>
          {fields.eyebrow}
        </h4>
        <TextCta
          className={"lgmax:pr-4"}
          kind={"black"}
          label={fields.allButtonText}
          icon={<Arrow />}
          to={"/insights"}
        />
      </div>
      {fields.topics.length > 0 && (
        <div className="relative col-span-12">
          <EmblaCarousel
            navigationType={"overlay"}
            navigationCallback={navigationCallback}
            loops={true}
            speed={3}
            reset={reset}
          >
            {fields.topics.map((topic) => (
              <div className="lgmax:h-screen" key={topic.sys.id}>
                <div className="aspect-[0] scale-100 overflow-hidden lg:aspect-video lgmax:h-full">
                  {topic.fields.image && (
                    <CenteredImageZoomInOnView
                      initialScale={1.3}
                      inViewScale={1}
                      className={`h-full w-full`}
                      image={topic.fields.image.fields}
                      bg="fade"
                    />
                  )}
                </div>
              </div>
            ))}
          </EmblaCarousel>
          {isVisible && (
            <div className="pointer-events-none absolute inset-0 flex flex-col items-center justify-center gap-10 text-center text-white">
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 1, delay: 1 } }}
                className="eyebrows"
                key={fields.eyebrow}
              >
                {fields.eyebrow}
              </motion.span>
              <div className="flex h-24 items-center">
                <FlutterText
                  className="h1"
                  text={fields.topics[_selected].fields.title}
                />
              </div>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 1, delay: 1 } }}
                key={fields.topicButtonText}
              >
                <RoundedCta
                  className={"pointer-events-auto"}
                  kind={"white"}
                  label={fields.topicButtonText}
                  icon={<Arrow />}
                  to={`/insights/${fields.topics[_selected].fields.slug}`}
                />
              </motion.div>
            </div>
          )}
        </div>
      )}
    </section>
  );
}
