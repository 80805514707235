import { LinkProps, PrefetchPageLinks } from "@remix-run/react";
import { useContext } from "react";
import { To, createPath } from "react-router-dom";
import { usePageTransition } from "~/contexts/PageTransitionProvider";
import { StorybookContext } from "../utils/StorybookContext";
import { usePrefetchBehavior } from "../utils/usePrefetchBehavior";

// https://github.com/remix-run/remix/blob/main/packages/remix-react/components.tsx#L287
const ABSOLUTE_URL_REGEX = /^(?:[a-z][a-z0-9+.-]*:|\/\/)/i;

export const getNavLinkUrl = (url?: To) => {
  if (url) {
    if (
      typeof url === "string" &&
      (url?.startsWith("http") ||
        url?.startsWith("?") ||
        url?.startsWith("//assets.ctfassets.net"))
    )
      return url;
    return typeof url === "string" && url.startsWith("/") ? url : `/${url}`;
  }
  return "/";
};

export default function NavOrExternalLink(props: LinkProps) {
  const isStorybook = !!useContext(StorybookContext);
  if (isStorybook) {
    props = { ...props };
    delete props.prefetch;
  }
  const { to, children, className, onClick, ...otherProps } = props;

  const { handleNavigate } = usePageTransition();
  let href = typeof to === "string" ? to : createPath(to);
  const [shouldPrefetch, prefetchHandlers] = usePrefetchBehavior(
    props?.prefetch || "none",
    props
  );
  const isAbsolute = typeof to === "string" && ABSOLUTE_URL_REGEX.test(to);

  if (
    href.startsWith("http") ||
    href.startsWith("//assets.ctfassets.net") ||
    href.startsWith("mailto")
  ) {
    return (
      <a
        href={href}
        className={className}
        onClick={onClick}
        target="_blank"
        rel="noreferrer"
        {...otherProps}
      >
        {children}
      </a>
    );
  }

  href = getNavLinkUrl(href);

  return (
    <a
      role="button"
      onClick={(e) => {
        e.preventDefault();
        handleNavigate(e)(to, onClick, props);
      }}
      className={className}
      href={href}
      {...otherProps}
      {...prefetchHandlers}
    >
      {children}
      {shouldPrefetch && !isAbsolute ? <PrefetchPageLinks page={href} /> : null}
    </a>
  );
}
