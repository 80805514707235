import {
  IHomeVideoHeroFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { motion, useScroll, useTransform } from "framer-motion";
import { useMedia, useWindowSize } from "react-use";
import clsx from "clsx";
import FlutterText from "../animations/flutterText";
import { ContentfulCenteredImage } from "../shared";
import { PAGE_TRANSITION_DELAY } from "~/contexts/PageTransitionProvider";
import { useNavigation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

type HomeVideoHeroProps = {
  fields: SpecificLocaleFields<IHomeVideoHeroFields>;
};

const TextBox = ({
  fields,
  className = "",
  show = true,
}: {
  fields: SpecificLocaleFields<IHomeVideoHeroFields>;
  className?: string;
  show?: boolean;
}) => (
  <div
    className={clsx(
      className,
      "absolute inset-0 flex h-full w-full flex-col justify-center"
    )}
  >
    <div className="flex w-full flex-col items-center justify-center">
      <h1 className="eyebrows col-span-full mb-6 text-center lg:col-span-4 lg:col-start-5">
        {fields.eyebrow}
      </h1>
      {show && (
        <FlutterText
          text={fields.title}
          className="h1 col-span-full max-w-[1000px] text-center lg:col-span-8 lg:col-start-3"
          as="h2"
          aria-label={fields.title}
        />
      )}
    </div>
  </div>
);

interface DynamicVideoProps {
  fields: SpecificLocaleFields<IHomeVideoHeroFields>;
}

const DynamicVideo = ({ fields }: DynamicVideoProps) => {
  const navigation = useNavigation();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showVideo, setShowVideo] = useState(false);
  const isLarge = useMedia("(min-width: 1024px)", false);

  useEffect(() => {
    setShowVideo(true);
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [isLarge]);

  if (navigation.state !== "loading" && showVideo) {
    return (
      <div
        className={
          !isLarge
            ? "absolute inset-0 flex h-full w-full items-center justify-center overflow-hidden lg:hidden"
            : "relative flex aspect-video h-auto w-full overflow-hidden lgmax:hidden"
        }
      >
        <video
          ref={videoRef}
          id={!isLarge ? "video" : "video-large"}
          className={
            !isLarge
              ? "absolute top-0 h-auto min-h-[90vh] w-full"
              : "absolute top-0 h-auto w-full lgmax:hidden"
          }
          playsInline
          autoPlay
          muted
          loop
        >
          <source
            src={
              !isLarge && fields.mobileVideo
                ? fields.mobileVideo.fields?.file?.url
                : fields.video.fields?.file?.url
            }
          />
          <img
            src={fields.fallbackImage.fields.image?.fields?.file?.url}
            title="Your browser does not support the <video> tag"
            alt={fields.fallbackImage.fields.alt}
          />
          <track
            src="/noCaptions.vtt"
            kind="captions"
            srcLang="en"
            label="english captions"
          />
          Unsupported video.
        </video>
      </div>
    );
  }

  return (
    <ContentfulCenteredImage
      className="absolute inset-0 h-full w-full lg:hidden"
      image={fields.fallbackImage.fields}
    />
  );
};

export default function HomeVideoHero({ fields }: HomeVideoHeroProps) {
  const { height } = useWindowSize();
  const { scrollY } = useScroll();
  const opacityTransform = useTransform(scrollY, [0, height * 0.75], [1, 0]);

  const scrollToContent = () => {
    window.scrollTo({
      top: height,
      behavior: "smooth",
    });
  };

  return (
    <section className="relative mx-auto h-[calc(100vh-80px)] min-h-hero-min w-screen lg:h-[calc(100vh-96px)]">
      {/* // height is screen minus nav minus space for bottom explore eyebrow */}
      <motion.div
        className="relative h-[calc(100%-40px)] w-full"
        style={{
          opacity: opacityTransform,
        }}
      >
        <div className="absolute left-4 flex h-full w-[calc(100%-32px)] justify-center lg:left-9 lg:w-[calc(100%-72px)]">
          <TextBox className="z-10 text-white" fields={fields} />
          <div className="absolute inset-0 flex h-full w-full items-start justify-center overflow-hidden text-white">
            <DynamicVideo fields={fields} />
          </div>

          <motion.div
            className="absolute inset-0 z-10 flex h-full bg-white"
            initial={{
              clipPath:
                "polygon(0% 0%, 0% 100%, 49.5% 100%, 49.5% 0%, 50.5% 0%, 50.5% 100%, 49.5% 100%, 49.5% 100%, 100% 100%, 100% 0%)",
              WebkitClipPath:
                "polygon(0% 0%, 0% 100%, 49.5% 100%, 49.5% 0%, 50.5% 0%, 50.5% 100%, 49.5% 100%, 49.5% 100%, 100% 100%, 100% 0%)",
            }}
            whileInView={{
              clipPath:
                "polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 100%, 100% 100%, 100% 0%)",
              WebkitClipPath:
                "polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 100%, 100% 100%, 100% 0%)",
            }}
            viewport={{ once: true, amount: "some" }}
            transition={{
              duration: 1.3,
              ease: [1, 0.08, 0.29, 0.67],
              delay: PAGE_TRANSITION_DELAY / 1000,
            }}
          ></motion.div>
        </div>
      </motion.div>

      <div className="flex-center z-10 col-span-full h-10 w-full">
        <motion.button
          className="min-w-max p-4 text-14 uppercase"
          style={{
            opacity: opacityTransform,
          }}
          onClick={scrollToContent}
          whileHover="hover"
          whileInView="noHover"
        >
          Explore
          <motion.div
            className="flex border-b border-solid border-black content-['']"
            variants={{
              hover: {
                transform: "scaleX(1)",
              },
              noHover: {
                transform: "scaleX(0)",
              },
            }}
            transition={{
              duration: 0.2,
              ease: "easeInOut",
            }}
          />
        </motion.button>
      </div>
    </section>
  );
}
